@import "src/scss/module";

.menu {
  background-color: $blue-darker;
  height: 100%;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  [class*="nav-item"] {
    display: flex;
    justify-content: space-between;

    [type="button"] {
      transition: 0.3s;
      border: 0;
      padding-left: 0;

      svg {
        transition: $transition-base;
      }

      &:hover {
        svg {
          fill: $blue;
        }
      }
    }

    &[class*="open"] {
      [type="button"] {
        transform: rotate(180deg);
      }
    }
  }

  [class*="nav-link"] {
    color: $white;
    font-weight: $font-weight-bold;
    white-space: nowrap;

    &:hover {
      color: $blue;
    }

    &[class*="active"] {
      color: $blue;

      &:hover {
        color: $white;
      }
    }
  }

  &__logo {
    fill: $white;
  }

  &__connect {
    flex-wrap: wrap;
    justify-content: flex-start !important;
    gap: $spacer-3;

    b {
      width: 100%;
      font-weight: normal;
      font-size: 12px;
      color: $blue;
    }

    a {
      svg {
        fill: $white;
      }

      &:hover {
        svg {
          fill: $blue !important;
        }
      }
    }
  }

  &__close {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    background-color: rgba($white, 0.1);
    color: $white !important;
    border-left: 1px solid rgba($white, 0.4);
    border-radius: 0;
    gap: $spacer-2;

    span {
      writing-mode: vertical-lr;
      rotate: 180deg;
    }

    &:hover {
      background-color: rgba($white, 0.2);
    }
  }
}
