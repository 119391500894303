@import "module";

:root {
  --bs-font-sans-serif: Quicksand, sans-serif;
  --bs-body-font-weight: #{$font-weight-medium};
}

html {
  font-size: 18px;
}

body {
  background-color: $white;
  min-width: 320px;
  overflow-x: hidden;

  @include media-breakpoint-down(md) {
    padding: 0 !important;
  }
}

* {
  font-family: Quicksand, sans-serif !important;
}

#__next {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 320px;
  overflow-x: hidden;
}

hr {
  border-bottom-color: #b4b4b5;
}

strong {
  color: $blue-dark;
  text-shadow: 0 0 5px rgba(255, 255, 255, 1);
}

h3,
.h3 {
  font-size: 36px;
}

h4,
.h4 {
  font-weight: $font-weight-semibold;
}

h5,
.h5 {
  font-size: 20px;
}

p,
li {
  a {
    font-weight: $font-weight-bold;
  }
}

i {
  color: $blue;
  font-style: normal;
}

.text-muted {
  color: #{$grey} !important;
}

.gradient {
  &-vertical {
    &-blue {
      &-white {
        background: linear-gradient(0deg, rgba(0, 174, 239, 0) 0%, rgba(0, 174, 239, 0.1) 100%);
      }
    }

    &-white {
      &-blue {
        background: linear-gradient(0deg, rgba(0, 174, 239, 0.1) 0%, rgba(0, 174, 239, 0) 100%);
      }
    }
  }
}

.form-check-input {
  &:checked {
    background-color: $blue;
    border-color: $blue;
  }
}

.sr-only {
  display: none;
}

.accordion {
  --bs-accordion-active-bg: $white;
  --bs-accordion-active-color: $grey-dark;
  --bs-accordion-btn-focus-box-shadow: none;
  --bs-accordion-border-width: 0;
  --bs-accordion-border-radius: 0;
  --bs-accordion-inner-border-radius: 0;

  &-item {
    border-bottom: 1px solid $grey-light;
  }

  &-collapse.show {
    border-bottom: 1px solid $grey;
    margin-bottom: -1px;
  }
}

.btn {
  font-weight: $font-weight-bold;

  &-blue {
    color: $white;

    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: $blue-dark;
      border-color: $blue-dark;
      color: $white;
    }
  }

  &-outline-blue {
    color: $blue;
    background-color: $white;
    border-color: $blue;

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: $white;
      background-color: $blue;
      border-color: $blue;
    }
  }

  &-link {
    color: $grey-dark;
    border: 0;
    border-bottom: 2px solid $blue !important;
    border-radius: 0;
    padding: $spacer-1 0;
    text-decoration: none !important;

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: $blue;
    }
  }
}

.primaryBtn {
  @extend .btn;
  @extend .btn-blue-dark;
  @extend .mb-3;
  @extend .me-3;
}
.secondaryBtn {
  @extend .btn;
  @extend .btn-blue;
  @extend .mb-3;
  @extend .me-3;
}

.dropdown {
  &-menu {
    --bs-dropdown-link-active-bg: #{$blue};
  }

  &-toggle {
    &::after {
      border: 0;
      width: 16px;
      height: 9px;
      transition: 0.3s;
      background-image: escape-svg(
        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 9' fill='#{$grey-dark}'><path d='M7.09949 8.62341C7.59734 9.12553 8.40586 9.12553 8.90371 8.62341L15.2762 2.19627C15.7741 1.69415 15.7741 0.87871 15.2762 0.37659C14.7784 -0.12553 13.9699 -0.12553 13.472 0.37659L7.99961 5.89589L2.52722 0.380607C2.02936 -0.121513 1.22085 -0.121513 0.722999 0.380607C0.225146 0.882727 0.225146 1.69817 0.722999 2.20029L7.09551 8.62743L7.09949 8.62341Z'/></svg>")
      );
    }

    &.show {
      &::after {
        transform: rotate(180deg);
      }
    }
  }
}

.carousel {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &-control-prev {
    @include media-breakpoint-down(md) {
      top: initial;
      bottom: -$spacer-5;
    }
  }

  &-control-next {
    @include media-breakpoint-down(md) {
      top: initial;
      bottom: -$spacer-5;
    }
  }

  &-inner {
    order: 1;
  }

  &-indicators {
    width: 100%;
    position: relative;
    order: 2;
    display: flex;
    gap: $spacer-2;

    [type="button"] {
      background-clip: initial;
      width: 14px;
      height: 14px;
      border-radius: 100px;
      overflow: hidden;
      background-color: $grey-light;
      border: 0;
      opacity: 1;
    }

    .active {
      background-color: $blue;
    }
  }
}
