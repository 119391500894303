@import "src/scss/module";

.connect {
  display: flex;
  flex-direction: row;
  gap: $spacer-4;

  a {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: $blue;
      transition: 0.3s;
    }

    &:hover {
      svg {
        fill: $blue-light;
      }
    }
  }
}
