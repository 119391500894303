@import "src/scss/module";

.header {
  background-color: $white;
  height: 70px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-width: 100%;
  z-index: $zindex-sticky;
  box-shadow: $box-shadow-sm;
  transition: 0.3s;

  @include media-breakpoint-up(md) {
    height: 90px;
  }

  //[class="Logo_svg__works"],
  //[class="Logo_svg__health"] {
  //  display: none;
  //
  //  @include media-breakpoint-up(md) {
  //    display: block;
  //  }
  //}

  &__logo {
    height: 45px;
    transition: 0.3s;

    @include media-breakpoint-up(md) {
      height: 60px;
    }
  }

  &__nav {
    display: flex;
    flex-direction: row;
    gap: $spacer-2;

    @include media-breakpoint-down(md) {
      background-color: $grey-light;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      justify-content: space-evenly;
    }

    @include media-breakpoint-up(md) {
      gap: $spacer-4;
    }

    @include media-breakpoint-up(lg) {
      gap: $spacer-5;
    }

    a {
      font-weight: $font-weight-bold;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    gap: $spacer-2;

    @include media-breakpoint-up(lg) {
      gap: $spacer-5;
    }

    button {
      background-color: $blue;
      border-radius: 100px;
      border: 0;
      width: 40px;
      height: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 12px 8px;
      transition: $transition-base;

      span {
        display: block;
        background-color: $white;
        height: 2px;
        width: 100%;
        border-radius: 10px;
      }

      &:hover {
        background-color: $blue-dark;
        transform: scale(1.1);
        box-shadow: $box-shadow-sm;
      }
    }
  }

  &__phone {
    a {
      color: $blue !important;
      font-weight: $font-weight-bold;
      transition: $transition-base;

      @include media-breakpoint-down(lg) {
        border: 1px solid $blue;
        border-radius: 100px;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      svg {
        fill: $blue;
        margin-right: $spacer-1;
        transition: $transition-base;

        @include media-breakpoint-down(lg) {
          fill: $grey-dark;
          margin-right: 0;
        }
      }

      &:hover {
        color: $blue-dark !important;

        svg {
          fill: $blue-dark;
        }
      }
    }
  }

  &__email {
    a {
      color: $blue !important;
      font-weight: $font-weight-bold;
      transition: $transition-base;

      @include media-breakpoint-down(lg) {
        border: 1px solid $blue;
        border-radius: 100px;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      svg {
        fill: $blue;
        margin-right: $spacer-1;
        transition: $transition-base;

        @include media-breakpoint-down(lg) {
          fill: $grey-dark;
          margin-right: 0;
        }
      }

      &:hover {
        color: $blue-dark !important;

        svg {
          fill: $blue-dark;
        }
      }
    }
  }

  &__toggle {
    transition: 0.3s;

    @include media-breakpoint-up(sm) {
      margin-right: $spacer-3;
    }

    span {
      transition: 0.3s;
    }

    &[class*="show"] {
      @include media-breakpoint-up(xl) {
        margin-right: 300px !important;
      }

      span {
        &:nth-of-type(1) {
          transform: translateY(0.3em) rotate(-45deg);
        }

        &:nth-of-type(2) {
          opacity: 0;
        }

        &:nth-of-type(3) {
          transform: translateY(-0.3em) rotate(45deg);
        }
      }
    }
  }
}
