@import "bootstrap/scss/variables";

$blue: #00aeef;
$blue-light: #e5f7fd;
$blue-dark: #034d7f;
$blue-darker: #002137;

$grey: #b4b4b5;
$grey-light: #ececec;
$grey-dark: #424244;
$grey-darker: #313133;

$theme-colors: (
  "blue": $blue,
  "blue-light": $blue-light,
  "blue-dark": $blue-dark,
  "blue-darker": $blue-darker,
  "grey": $grey,
  "grey-light": $grey-light,
  "grey-dark": $grey-dark,
  "grey-darker": $grey-darker,
);

$link-color: $blue;
$link-decoration: none;
$link-hover-color: $blue;
$link-hover-decoration: underline;

$small-font-size: 12px;

$spacer: 0.8333333333rem;
$spacer-0: 0;
$spacer-1: $spacer * 0.25;
$spacer-2: $spacer * 0.666666666;
$spacer-3: $spacer;
$spacer-4: $spacer * 1.666666666;
$spacer-5: $spacer * 2.86666666;
$spacer-6: $spacer * 5.333333333;
$spacer-7: $spacer * 7.333333333;

$spacers: (
  0: $spacer-0,
  1: $spacer-1,
  2: $spacer-2,
  3: $spacer-3,
  4: $spacer-4,
  5: $spacer-5,
  6: $spacer-6,
  7: $spacer-7,
);

$headings-color: $grey-dark;
$headings-font-weight: $font-weight-bold;

$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='#{$blue}'><path d='M11.2308 3.23077C11.2308 2.55 10.6808 2 10 2C9.31923 2 8.76923 2.55 8.76923 3.23077V8.76923H3.23077C2.55 8.76923 2 9.31923 2 10C2 10.6808 2.55 11.2308 3.23077 11.2308H8.76923V16.7692C8.76923 17.45 9.31923 18 10 18C10.6808 18 11.2308 17.45 11.2308 16.7692V11.2308H16.7692C17.45 11.2308 18 10.6808 18 10C18 9.31923 17.45 8.76923 16.7692 8.76923H11.2308V3.23077Z'/></svg>");
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='#{$blue}'><path d='M18 10C18 10.6914 17.45 11.25 16.7692 11.25H3.23077C2.55 11.25 2 10.6914 2 10C2 9.30859 2.55 8.75 3.23077 8.75H16.7692C17.45 8.75 18 9.30859 18 10Z'/></svg>");
$dropdown-toggle-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='#{$blue}'><path d='M18 10C18 10.6914 17.45 11.25 16.7692 11.25H3.23077C2.55 11.25 2 10.6914 2 10C2 9.30859 2.55 8.75 3.23077 8.75H16.7692C17.45 8.75 18 9.30859 18 10Z'/></svg>");

$carousel-control-prev-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 43' fill='#{$blue}'><path d='M3.76007 21.4999L22.8851 2.38993C23.3801 1.89493 23.3801 1.10493 22.8851 0.609932C22.3951 0.119932 21.6051 0.119932 21.1101 0.609932L1.11007 20.6149C0.620071 21.0999 0.620071 21.8899 1.10507 22.3799C1.1084 22.3833 1.11007 22.3866 1.11007 22.3899L21.1101 42.3849C21.6001 42.8799 22.3901 42.8799 22.8801 42.3949C22.8834 42.3916 22.8851 42.3883 22.8851 42.3849C23.3801 41.8999 23.3801 41.1099 22.8951 40.6199C22.8917 40.6166 22.8884 40.6149 22.8851 40.6149L3.76007 21.4999Z' /></svg>");
$carousel-control-next-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 43' fill='#{$blue}'><path d='M20.2399 21.4999L1.11493 2.38993C0.61993 1.89493 0.61993 1.10493 1.11493 0.609932C1.60493 0.119932 2.39493 0.119932 2.88993 0.609932L22.8899 20.6149C23.3799 21.0999 23.3799 21.8899 22.8949 22.3799C22.8916 22.3833 22.8899 22.3866 22.8899 22.3899L2.88993 42.3849C2.39993 42.8799 1.60993 42.8799 1.11993 42.3949C1.1166 42.3916 1.11493 42.3883 1.11493 42.3849C0.61993 41.8999 0.61993 41.1099 1.10493 40.6199C1.10826 40.6166 1.1116 40.6149 1.11493 40.6149L20.2399 21.4999Z' /></svg>");
